import {i18n} from '@/utils/i18n';

export const rules = {
    title: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.title')}), trigger: 'change' },
    ],
    short_text: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.short_text')}), trigger: 'change' },
    ],
    text: [
        { required: true, message: i18n.t('message.please_enter_input', {input: i18n.t('message.text')}), trigger: 'change' },
    ],
};
