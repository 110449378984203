<template>
  <div>
    <div class="app-modal__body">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <div style="display: flex; align-items: flex-start;">
            <h4 class=""> {{$t('message.products')}} - {{ dealPackages.length }} </h4> 
            <el-button @click="addNewPackage" class="ml-5 mt-3" type="success" plain size="medium"> {{ $t('message.add_package') }} </el-button>
          </div>
          <el-tabs v-model="currentName" type="card" closable @tab-remove="removePackageTab">
            <el-tab-pane v-for="(pack, index) in dealPackages" :key="pack.pack_id" :name="'pack_'+pack.pack_id">
              <span slot="label"> {{ $t('message.n') +' '+ (index+1) + ' / '  + pack.barcode}} </span>
              <div>
                <el-row :gutter="20" class="mb-2">
                  <el-col :span="8">
                    <select-container-type
                      class="w-100"
                      :size="'large'"
                      :select_default="true"
                      :clearable="false"
                      :add_query="{user_id: authUser.id}"
                      :placeholder="$t('message.containerType')"
                      :id="pack.container_type_id"
                      v-model="pack.container_type_id"
                    >
                    </select-container-type>
                  </el-col>
                  <el-col :span="8">
                    <el-input  :placeholder="$t('message.tracking_code_new')" v-model="pack.tracking_code" size="large">
                    </el-input>
                  </el-col>
                  <el-col :span="4">
                    <el-button
                      class="w-100"
                      @click="copyPackage(pack)" 
                      type="primary" 
                      icon="fa fa-copy" circle> 
                        {{ $t('message.copy') }}
                    </el-button>
                  </el-col>
                  <el-col :span="4">
                    <el-button v-if="pack.barcode" 
                      class="w-100"
                      @click="printBarCode(pack, 'waiting_barcode')" 
                      type="primary" 
                      icon="fa fa-print" circle> 
                        {{ $t('message.print') }}
                    </el-button>
                  </el-col>
                </el-row>
                <deal-products
                  :key="pack.pack_id" 
                  v-model="pack.deal_products" 
                  :packageProdList="deal_packages[index] ? deal_packages[index].deal_products : ''"
                  :copiedProducts="newCopiedPackageProducts"
                  :direction_tariff_id="direction_tariff_id"
                  :deal_id="deal_id"
                  :type="pack.type"
                > 
                </deal-products>
               </div>
            </el-tab-pane>
          </el-tabs>
        </div>
    </div>
    <div class="app-modal__body pt-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <h4 class=""> {{$t('message.marketplace')}} </h4>
          <deal-products  
            ref="productsTable" 
            v-model="dealProductsList"
            :packageProdList="deal_products"
            :direction_tariff_id="direction_tariff_id"
            :deal_id="deal_id"
				    :type="'product'"
            :partner_id="partner_id"
          >
          </deal-products>
        </div>
    </div>
   
  </div>
</template>

<script>
import dealProducts from "@/views/deals/components/dealProduct/deal-product-list";
import selectContainerType from "@/components/inventory/select-container-type";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import _ from 'lodash';

export default {
  components: {
    dealProducts,
    selectContainerType
  },
  mixins: [form, drawer],
  props: {
    partner_id: {
      default: null,
    },
    filial_id: {
      default: null,
    },
    deal_id: {
      default: null,
    },
    direction_tariff_id: {
      default: null,
    },
    deal_calculate_money: {
      default: 'deal',
    },
    deal_packages: {
      default: Array,
    },
    deal_products: {
      default: Array,
    },
    status: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      dealProductsList: [],
      total_weight: 0,
      product_id: null,
      updateProducts: false,
      selectedProductIndex: null,
      dealPackages: [],
      currentName: '',
      newCopiedPackageProducts: []
    };
  },
  watch: {
    direction_tariff_id: {
      handler: function (val) {
        if (val && this.dealProductsList && this.dealProductsList.length > 0) {
          this.updateProductPricesByTariff({
              products: this.dealProductsList.map(el => el.product_id),
              direction_tariff_id: val
            }).then(res => {
              this.dealProductsList.forEach((el, index)=> {
                let new_el = this.newPriceList.find(item => item.id === el.product_id );
                if(new_el){
                  this.$set(el, 'price', new_el.price);
                }else{
                  this.$set(el, 'price', 0);
                }
              })
            }).catch(err => {
              console.log(err, 'err');
            });
        } 
      },
      immediate: true,
      deep: true,
    },
    deal_packages: {
      handler: async function (newVal) {
        this.dealPackages = JSON.parse(JSON.stringify(newVal), true);
      },
      immediate: true,
      deep: true,
    },
    deal_products: {
      handler: async function (newVal) {
        this.dealProductsList = JSON.parse(JSON.stringify(newVal), true);
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      system_settings: "auth/system_settings",
      authUser: "auth/user",
      permissions: "auth/permissions",
      getProduct: "products/model",
      model: "dealProducts/model",
      dealProducts: "dealProducts/dealProducts",
      rules: "dealProducts/rules",
      columns: "dealProducts/columns",
      mode: "MODE",
      currencies: "currency/inventory",
      services: "productServices/inventory",
      newPriceList: "productTariffPrices/newPriceList",
    }),
    
  },
  methods: {
    ...mapActions({
      save: "dealProducts/store",
      update: "dealProducts/update",
      updateColumn: "dealProducts/updateColumn",
      show: "dealProducts/show",
      showProduct: "products/getWithTariffPrice",
      empty: "dealProducts/empty",
      emptyProduct: "products/empty",
      destroy: "dealProducts/destroy",
      deleteDealPackage: "packageForProductCirculations/deleteDealPackage",
      updateCurrencyInventory: "currency/inventory",
      updateProductPricesByTariff: "productTariffPrices/updateProductPricesByTariff",
      printPackageBarcode: "packageForProductCirculations/printPackageBarcode",
    }),
    removePackageTab(targetName){
      let tabs = JSON.parse(JSON.stringify(this.dealPackages));
      let pack_id = parseInt(targetName.replace('pack_','')); 
      let current_pack = tabs.find(el => el.pack_id === pack_id);
      this.currentName = '';
      this.$confirm(this.$t('message.do_you_really_want_to_do_this'), this.$t('message.warning'),
        { confirmButtonText: this.$t('message.yes'), cancelButtonText: this.$t('message.no'), type: "warning", })
      .then(() => {
        if(current_pack && current_pack.id){
          this.deleteDealPackage({id: current_pack.id})
            .then(res => {
              this.dealPackages = tabs.filter(el => el.pack_id != pack_id);
              this.$alert(res);
            });
        }else{
          this.dealPackages = tabs.filter(el => el.pack_id != pack_id);
        }
        
      })
      .catch(() => {
          this.$message({
              type: "warning",
              message: this.$t('message.operation_canceled')
          });
      });
    },
    afterOpen(){
      if(this.dealPackages.length > 0){
        this.currentName = 'pack_' + this.dealPackages[0].pack_id; 
      }
    },
    async afterLeave(){
      await this.dispatchAll();
      // await this.emptyProducts();
    },
    emptyProducts() {
      if(this.$refs.productsTable){
        this.$refs.productsTable.dealProductsList = []
      }
    },
    async dispatchAll(){
      this.$emit('input', this.dealProductsList);
      await this.updatePackWeight();
      this.$emit('updateDealPackages', this.dealPackages);
    },
    updatePackWeight(){
      for (let pack of this.dealPackages) {
        let total_weight = 0;
        let total_quantity = 0;
        let total_money = 0;
        let product_price = 0;
        if(pack.deal_products && pack.deal_products.length > 0){
          pack.deal_products.forEach(item => {
            total_weight  += (parseFloat(item.weight) || 0);
            total_quantity  += (parseFloat(item.quantity) || 0);
            product_price  += (parseFloat(item.product_price) || 0);
            total_money  += (parseFloat(item.weight * item.currency_rate * item.price) || 0);
          });
        }
        this.$set(pack, 'weight', total_weight);
        this.$set(pack, 'dp_weight', total_weight);

        this.$set(pack, 'quantity', total_quantity);
        this.$set(pack, 'dp_quantity', total_quantity);

        this.$set(pack, 'total_money', total_money);

        this.$set(pack, 'price', total_money);
        this.$set(pack, 'dp_price', total_money);
        
        this.$set(pack, 'product_price', product_price);
      }
    },
    addNewPackage(){
      this.newCopiedPackageProducts = [];
      let last_pack_id = 1;
      if(this.dealPackages.length > 0){
        let last_pack = this.dealPackages[this.dealPackages.length-1];
        last_pack_id = last_pack.pack_id + 1;
      }
      let pack = {
        id: '',
        type: 'package',
        pack_id: last_pack_id,
        barcode: '',
        container_type_id: '',
        weight: 0,
        quantity: 0,
        total_money: 0,
        deal_products: [],
        new_product: true,
        tracking_code: ''
      };
      this.dealPackages.push(JSON.parse(JSON.stringify(pack)));
    },
    check: function (column, event) {
      this.updateColumn({key: column, value: event,})
    },

    printBarCode(container, type) {
        let payload = {container_id: container.id, barcode: container.barcode}
        if(type){
            payload = {container_id: container.id, barcode: container.barcode, type: type}
        }
        this.printPackageBarcode(payload)
        .then(res => {
            const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
            WinPrint.document.write(res.data);
            WinPrint.document.close();
            WinPrint.focus();
            setTimeout(() => {
                WinPrint.print();
                WinPrint.close();
            }, 1000);
        })
        .catch(err => {
            this.$alert(err);
        });
    },
    
    copyPackage(pack){
      this.newCopiedPackageProducts = [];
      let last_pack_id = 1;
      if(this.dealPackages.length > 0){
        let last_pack = this.dealPackages[this.dealPackages.length-1];
        last_pack_id = last_pack.pack_id + 1;
      }
      let new_pack = JSON.parse(JSON.stringify(pack));
      new_pack.id = null;
      new_pack.barcode = '';
      new_pack.pack_id = last_pack_id;
      new_pack.new_product = true;
      if(new_pack.deal_products && Array.isArray(new_pack.deal_products)){
        new_pack.deal_products.forEach(prod => {
          prod.id = null;
          prod.dp_id = null;
        });
      }
      this.newCopiedPackageProducts = new_pack.deal_products ? new_pack.deal_products : [];
      this.dealPackages.push(new_pack);
    }
  },
};
</script>
<style>
  .header__class {
    font-size: 12px;
  }
  .row__class {
    padding: 0 !important;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
</style>
