import request from '@/utils/request';

export function index(params) {
    return request({
        url: '/news',
        method: 'get',
        params
    })
}
export function store(data) {
    return request({
        url: '/news',
        method: 'post',
        data
    })
}
export function inventory(params) {
    return request({
        url: "/news/inventory",
        method: "get",
        params
    });
}
export function show(id) {
    return request({
        url: `/news/${id}`,
        method: 'get'
    })
}

export function update(data) {
    return request({
        url: `/news/${parseInt(data.get('id'))}`,
        method: 'post',
        data
    })
}

export function destroy(id) {
    return request({
        url: `/news/${id}`,
        method: 'delete'
    })
}